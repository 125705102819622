$formControlRowHeight : 46px;

html,
body {
    margin: 0;
    padding: 0;
}

html {
    min-height: 100vh;
    height: 100%;
}

body {
    min-height: 100%;
}

a {
    cursor: pointer;

    &.dropdown-item.disabled,
    .dropdown-item:disabled {
        color: var(--gray) !important;
    }
}

.file-upload-form-group {
    cursor: pointer;

    .custom-file {
        font-size: 0.875rem;

        input {
            cursor: pointer;
            height: $formControlRowHeight;
        }

        .custom-file-label {
            color: #adb5bd;
            height: $formControlRowHeight;

            &::after {
                height: calc(1.5em + 1.45rem);
            }
        }
    }
}

.input-group-prepend .input-group-text.is-invalid,
.input-group-append .input-group-text.is-invalid,
ng-select.ng-select .ng-select-container.is-invalid,
ng-select.ng-select .ng-select-container.is-invalid+.ng-dropdown-panel {
    border-color: var(--orange) !important;
}

input[bsdatepicker]:read-only {
    background-color: #fff;
}

input.form-control:disabled {
    padding-left: 10px !important;
}

input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23f5365c'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
}

textarea.no-resize {
    resize: none;
}

a.dropdown-item,
button.dropdown-item {
    color: $gray-700;
    outline: none !important;

    &:hover {
        color: var(--primary);
    }
}

button.btn .btn-inner--icon i.fa {
    top: 0;
}

button {
    &.btn-tablue {
        background-color: $tablue;
        border-color: $tablue;
        color: #fff;

        &:hover {
            color: #fff;
        }
    }

    &.btn-outline-tablue {
        background-color: #fff;
        border-color: $tablue;
        color: $tablue;

        &:hover {
            color: $tablue;
        }
    }
}

.custom-toggle input:checked+.custom-toggle-slider {
    &.custom-toggle-tablue {
        border-color: $tablue;

        &::before {
            background-color: $tablue;
        }

        &::after {
            color: $tablue;
        }
    }
}

ngx-dropzone {
    border-color: $gray-300 !important;

    &.ngx-dz-disabled {
        background-color: $gray-200;
    }

    ngx-dropzone-label {
        color: $gray-700;
        font-weight: 300;
    }
}

tabset {
    .nav-pills .nav-link {
        background-color: #fff;
        border-color: $tablue;
        color: $tablue;

        &:hover {
            color: $tablue;
        }

        &.active {
            background-color: $tablue;
            border-color: $tablue;
            color: #fff;
        }
    }
}

.text-link {
    color: var(--primary);

    &:hover {
        text-decoration: underline;
    }
}

.custom-checkbox,
.custom-radio {
    .custom-control-input~.custom-control-label {
        height: unset !important;
        min-height: 1rem;
    }
}

.guidance-container * {
    font-size: 0.875rem !important;

    .input-group-prepend .input-group-text {
        background: $gray-200;
    }

    .form-control {
        height: unset;
    }
}

.form-control-label-small {
    font-size: 80%;
}

.client-logo-container img.client-logo {
    max-height: $formControlRowHeight;
}

.table-menu-ellipsis i {
    color: var(--gray);

    &:hover {
        color: var(--primary);
    }
}

.table-filter-details {
    opacity: 0;
    transition: .25s all ease-in;

    &.show {
        opacity: 1;
    }

    .table-filters {
        background-color: $gray-100;
        border-bottom: 1px solid #e9ecef;
    }
}

.modal .modal-full {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;

    .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;

        .modal-container {
            min-height: 100vh;
            display: flex;
            flex-direction: column;

            .code-preview {
                background-color: #f1f1f1;

                object {
                    border: 1px solid $gray-300;
                    border-radius: 0.25rem;
                }
            }
        }
    }
}

// .datatable-header-inner {
//     width: 100% !important;
// }

// .datatable-scroll {
//     display: inherit !important;
// }

// .datatable-row-center {
//     width: 100% !important;
// }

// .datatable-body-row {
//     width: 100% !important;
// }