ng-select.ng-select {

    .ng-value,
    .ng-value-label {
        display: flex;
        color: $gray-700;
        font-weight: 400;
        font-size: 0.875rem;
    }
}

ng-select.ng-select.ng-select-form-control {
    font-size: 0.875rem;
    min-height: 46px !important;

    &.ng-select-opened {
        .ng-select-container {
            border-color: #5e72e4;
        }

        ng-dropdown-panel {
            border-color: #5e72e4;
        }
    }

    &.ng-select-disabled>.ng-select-container {
        background-color: $gray-200;
    }

    .ng-select-container {
        min-height: 46px !important;
        border: 1px solid #dee2e6;

        &:hover {
            box-shadow: none !important;
        }

        .ng-placeholder {
            color: #adb5bd;
            top: 11px !important;
        }

        .ng-value-container .ng-input {
            top: 11px !important;
        }
    }
}

ng-select.ng-select.navbar-clients {
    width: 100%;
    min-width: 375px !important;
    min-height: 40px;

    .ng-select-container {
        height: 40px !important;
        background-color: rgba(255, 255, 255, .8);
        border-color: #dee2e6;
        cursor: pointer;
        transition: background-color .3s cubic-bezier(.68, -.55, .265, 1.55);
    }

    .ng-dropdown-panel-items .ng-option:first-child {
        border-bottom: 1px solid $gray-200 !important;
    }
}

@media (min-width: 575.99px) {
    ng-select.ng-select.navbar-clients:hover {
        .ng-select-container {
            background-color: #fff;
        }
    }
}

@media (max-width: 575.98px) {
    ng-select.ng-select.navbar-clients {
        min-width: 250px !important;
        width: calc(100% - 15%) !important;

        &.ng-select-opened {
            .ng-select-container {
                background-color: #fff;
            }
        }
    }
}