ngx-monaco-editor {
    &.editor {
        height: 100%;
    }

    .editor-container {
        height: 100% !important;
        .overflow-guard {
            border: 1px solid $gray-300;
            border-radius: 0.25rem;
        }
    }
}